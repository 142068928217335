import { graphql, Link } from "gatsby"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"
import Layout from "../components/Layout"

import /* FiChevronRight */ "react-icons/fi"
import { Body } from "../theme/typography"
import { ImageCard } from "../components/Cards"
import SocialIcons from "../components/SocialIcons"
import Seo from "../components/Seo"

const HomePageContainer = styled.div`
  display: grid;
  grid-auto-flow: row;
  padding: 0;
  gap: 32px;
  justify-items: stretch;
  justify-content: center;

  justify-self: center;
`

const BioContainer = styled.div`
  display: grid;
  grid-auto-flow: row;
  padding: 0;
  gap: 24px;
`
const Statement = styled.div`
  color: var(--base11);
  display: grid;
  grid-auto-flow: row;
  gap: 16px;
  padding: 24px 16px;
  border-top: 1px solid var(--base6);
  border-bottom: 1px solid var(--base6);
`

const ProfilePic = styled(GatsbyImage)`
  width: 112px;
  height: 112px;

  border-radius: 50%;
  position: relative;
  z-index: 10;
  overflow: visible;

  > * {
    border-radius: 50%;
  }

  ::after {
    content: "";
    position: absolute;
    top: 2px;
    right: 0;
    bottom: -2px;
    left: 0;
    background: var(--base11);
    z-index: -1;
    filter: blur(8px);
    opacity: 0.15;
    border-radius: 50%;
  }

  @media screen and (max-width: 400px) {
    width: 80px;
    height: 80px;
  }
`

const Name = styled.h1`
  font-weight: 900;
  font-size: 32px;
  white-space: nowrap;
  color: var(--base12);

  @media screen and (max-width: 400px) {
    font-size: 24px;
  }
`

const ProfileContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  gap: 12px;
  align-items: center;
`

const Section = styled.div`
  display: grid;
  grid-auto-flow: row;
  gap: 16px;
`

const SectionLink = styled(Link)`
  display: grid;
  gap: 0px;
  grid-auto-flow: column;
  align-items: end;
  justify-self: start;
  font-weight: 600;
  font-size: 28px;
  line-height: 100%;
  padding: 0 8px 0 16px;
  transition: padding 50ms ease-in-out;
  text-decoration: none;
  color: var(--base12);

  svg {
    font-size: 24px;
    opacity: 0.25;
    transition: padding 50ms ease-in-out, opacity 50ms ease-in-out;
    margin-bottom: 1px;
  }

  :hover {
    padding: 0 6px 0 16px;

    svg {
      opacity: 1;
      padding-left: 2px;
    }
  }
`

const ImageCardContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  overflow: visible;

  @media screen and (max-width: 400px) {
    grid-template-columns: 1fr;
  }
`

const IndexPage = ({ data }) => {
  const profile = getImage(data.profile)
  return (
    <Layout style={{ background: "#f7f7f7" }}>
      <Seo title="Home" />
      <HomePageContainer>
        <BioContainer>
          <ProfileContainer>
            <ProfilePic
              image={profile}
              imgStyle={{ borderRadius: "50%" }}
              alt="Profile Picture"
            />
            <Name>
              Pablo Mikel <br />
              Olavarrieta
            </Name>
          </ProfileContainer>
          <Statement>
            <Body>
              I’m a designer creating web and mobile experiences at CueBack.
            </Body>
            <Body>
              I graduated from Santa Clara University in June 2021 with degrees
              in marketing and psychology.
            </Body>
          </Statement>
        </BioContainer>
        <SocialIcons />
        <Section>
          <SectionLink /* to="" */>
            <h2>Projects</h2>
            {
              // <FiChevronRight strokeWidth={2.5} />
            }
          </SectionLink>
          <ImageCardContainer>
            {data.allContentfulProject.edges.map(edge => {
              const project = edge.node
              return (
                <ImageCard
                  key={project.slug}
                  title={project.title}
                  image={project.featuredImage}
                  slug={`projects/${project.slug}`}
                />
              )
            })}
          </ImageCardContainer>
        </Section>
        <Section>
          <SectionLink /* to="" */>
            <h2>Photos</h2>
            {
              // <FiChevronRight strokeWidth={2.5} />
            }
          </SectionLink>
          <ImageCardContainer>
            {data.allContentfulPhotoCollection.edges.map(edge => {
              const photoCollection = edge.node
              return (
                <ImageCard
                  key={photoCollection.slug}
                  title={photoCollection.title}
                  image={photoCollection.images[0]}
                  slug={`photos/${photoCollection.slug}`}
                />
              )
            })}
          </ImageCardContainer>
        </Section>
      </HomePageContainer>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query {
    profile: file(name: { eq: "profile" }) {
      childImageSharp {
        gatsbyImageData(aspectRatio: 1, width: 800)
      }
    }
    allContentfulProject {
      edges {
        node {
          slug
          title
          featuredImage {
            title
            gatsbyImageData(aspectRatio: 1.3333333333)
          }
        }
      }
    }
    allContentfulPhotoCollection {
      edges {
        node {
          slug
          title
          images {
            title
            gatsbyImageData(aspectRatio: 1.3333333333)
          }
        }
      }
    }
  }
`
