import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import * as React from "react"
import styled from "styled-components"
import { SubHeader } from "../theme/typography"

const ImageCardWrapper = styled(Link)`
  display: grid;
  grid-auto-flow: row;
  border-radius: 16px;
  text-decoration: none;
  color: var(--base12);
  box-sizing: border-box;
  position: relative;
  background: var(--base1);
  overflow: visible;

  :after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    border: 1px solid var(--base6);
    border-radius: 16px;
    z-index: 100;
  }

  :before {
    content: "";
    opacity: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 0;
    background: #fff;
    border-radius: 16px;
    box-shadow: 0px 0px 4px var(--base12), 0px 4px 8px var(--base12);
    transition: opacity 50ms ease-in-out;
  }

  :hover {
    :before {
      opacity: 0.05;
    }
  }
`

const CardTitle = styled(SubHeader)`
  padding: 12px 16px 16px 16px;
  text-align: center;
  border-top: 1px solid var(--base6);
  background: var(--base1);
  z-index: 10;
  border-radius: 0 0 16px 16px;
`

const FeaturedImage = styled(GatsbyImage)`
  border-radius: 16px 16px 0px 0px;
  overflow: hidden;
  width: 100%;

  > * {
    border-radius: 16px 16px 0px 0px;
    overflow: hidden;
  }
`

const ImageCard = ({ title, image, slug }) => {
  return (
    <ImageCardWrapper to={slug}>
      <FeaturedImage
        image={image.gatsbyImageData}
        imgStyle={{ borderRadius: "16px 16px 0px 0px" }}
        alt={image.title}
      />
      <CardTitle>{title}</CardTitle>
      {/*       <Shadow></Shadow>
       */}
    </ImageCardWrapper>
  )
}

export { ImageCard }
