import * as React from "react"
import styled from "styled-components"
import { FiFacebook, FiInstagram, FiMail, FiTwitter } from "react-icons/fi"

const SocialIconsContainer = styled.div`
  padding: 0 0 32px 0;
  display: flex;
  justify-content: space-around;
  font-size: 20px;
  border-bottom: 1px solid var(--base6);
`

const FooterIcon = styled.a`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--base4);
  border-radius: 50%;
  color: var(--base11);
  border: 1px solid var(--base6);

  :hover {
    color: var(--base12);
    background: var(--base6);
    border: 1px solid var(--base8);
  }
`

const SocialIcons = () => {
  return (
    <SocialIconsContainer>
      <FooterIcon href={`https://twitter.com/pablo__mikel`} target={`_blank`}>
        <FiTwitter />
      </FooterIcon>
      <FooterIcon
        href={`https://www.facebook.com/pablomikelolavarrieta`}
        target={`_blank`}
      >
        <FiFacebook />
      </FooterIcon>
      <FooterIcon
        href={`https://www.instagram.com/pablo.olavarrieta/`}
        target={`_blank`}
      >
        <FiInstagram />
      </FooterIcon>
      <FooterIcon href={`mailto:me@pablomikel.xyz`}>
        <FiMail />
      </FooterIcon>
    </SocialIconsContainer>
  )
}

export default SocialIcons
